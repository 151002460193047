



































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListItem from '@/components/AppListItem.vue';
import COOP_ROUTES from '@/graphql/queries/CoopRoutes.graphql';
import formatDate from '@/utils/formatDate';
import pluralNumber from '@/utils/pluralNumber';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { CoopRoutesQuery, CoopRoutesQueryVariables } from '@/types/schema';

export default Vue.extend({
  name: 'CoopFarmTickets',
  components: {
    AppMain,
    AppEmpty,
    AppPanel,
    AppPanelItem,
    AppListItem,
  },
  data() {
    return {
      coopRoutes: [] as CoopRoutesQuery['coopRoutes'],
    };
  },
  computed: {
    routes(): CoopRoutesQuery['coopRoutes'] {
      const reversed = [...this.coopRoutes].reverse();
      return reversed;
    },
  },
  methods: {
    formatDate,
    getSubtitle(route: CoopRoutesQuery['coopRoutes'][0]) {
      const date = formatDate(route.date);
      if (!route.farmTickets) return date;
      const photos = pluralNumber(route.farmTickets.length, 'Photo');
      return `${date} - ${photos}`;
    },
  },
  apollo: {
    coopRoutes: {
      query: COOP_ROUTES,
      variables(): CoopRoutesQueryVariables {
        const today = new Date();
        const DAYS = 10;
        const start = format(subDays(today, DAYS), 'yyyy-MM-dd');
        const end = format(today, 'yyyy-MM-dd');
        return { range: { start, end } };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
